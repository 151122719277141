import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import { v4 as uuidv4 } from "uuid";
import { Container } from "components/style";
import ContactsComponent from "components/pages/index/school-contacts/ContactsComponent";

import PricePackagesModal from "./modal";
import * as SC from "./style";

const CoursePricePackages = ({ pricePackages = [], courseData = {} }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [chosenPackage, setChosenPackage] = useState(
    pricePackages.find(({ prismicType }) => prismicType === "group")
  );

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <SC.StyledSection>
      <Container>
        <Modal
          center
          closeIcon={<div />}
          open={modalIsOpen}
          onClose={closeModal}
          focusTrapped={false}
          classNames={{
            overlay: "customOverlay_black",
            modal: "customModal",
          }}
        >
          <PricePackagesModal
            pricePackages={pricePackages}
            chosenPackage={chosenPackage}
            closeModal={closeModal}
            courseData={courseData}
          />
        </Modal>
        <SC.StyledSectionTitleGaramond>
          <span>Пакеты</span> Курса
        </SC.StyledSectionTitleGaramond>
        <SC.PackagesWrapper>
          <SC.StyledGranichCat />
          {pricePackages.map(pricePackage => {
            const { benefits, isIncludeCourseTags, packageName, prismicType } =
              pricePackage;

            const isCourseClosed =
              prismicType === "group" && !courseData?.coursestatus;

            return (
              <SC.PackageBlock key={uuidv4()}>
                <SC.PackageCategoryHeader>
                  <SC.PackageTitleWrapper>
                    <SC.PackageCategoryLabel>Пакет</SC.PackageCategoryLabel>
                    <SC.PackageCategoryTitle>
                      {packageName}
                    </SC.PackageCategoryTitle>
                  </SC.PackageTitleWrapper>
                  {isIncludeCourseTags && (
                    <SC.StyledCourseTags
                      course={courseData}
                      withCourseType={false}
                      withAdditionalTags={false}
                    />
                  )}
                </SC.PackageCategoryHeader>
                <SC.PackageBenefitsWrapper>
                  {benefits.map(({ text, isIncluded }, idx) => (
                    <SC.PackageBenefit key={idx} $isIncluded={isIncluded}>
                      <span>
                        {isIncluded ? (
                          <SC.StyledCheckIcon />
                        ) : (
                          <SC.StyledCrossIcon />
                        )}
                      </span>
                      {text}
                    </SC.PackageBenefit>
                  ))}
                </SC.PackageBenefitsWrapper>
                <SC.PackageFooter>
                  <SC.PackagePrice>
                    {courseData[`course_price_${prismicType}`]} ₽
                  </SC.PackagePrice>
                  <SC.StyledButtonComponent
                    $isCourseClosed={isCourseClosed}
                    disabled={isCourseClosed}
                    icon={
                      <SC.FoundationLogoWrapper>
                        <SC.StyledFoundationLogo />
                      </SC.FoundationLogoWrapper>
                    }
                    target="_blank"
                    theme="red"
                    onClick={() => {
                      if (!isCourseClosed) {
                        openModal();
                        setChosenPackage(pricePackage);
                      }
                    }}
                  >
                    {isCourseClosed ? "Поток закрыт" : "Оплатить"}
                  </SC.StyledButtonComponent>
                </SC.PackageFooter>
              </SC.PackageBlock>
            );
          })}
        </SC.PackagesWrapper>
        <SC.InfoBeforeBuying>
          <SC.InfoBeforeBuyingColumn>
            <SC.InfoItem>
              <SC.InfoTitle>
                <span>➀</span>
                Учебное расписание в Группе
              </SC.InfoTitle>
              <SC.InfoText>
                Покупая Курс пакета «Группа» вы в любом случае получаете доступ
                ко всем урокам и ДЗ, но не сразу, как в пакете «Соло», а
                согласно учебному расписанию потока.
              </SC.InfoText>
            </SC.InfoItem>
            <SC.InfoItem>
              <SC.InfoTitle>
                <span>➁</span>
                Штрафы дедлайнов!
              </SC.InfoTitle>
              <SC.InfoText>
                Если вы не выполните экзаменационную часть урока в установленный
                к нему дедлайн, то лишаетесь возможности получить видеоразбор от
                куратора по ДЗ конкретно этого урока. Да, на Курсе дедлайны со
                штрафами (разумеется речь идёт о пакете «Группа»).
              </SC.InfoText>
            </SC.InfoItem>
            <SC.InfoItem>
              <SC.InfoTitle>
                <span>➂</span>
                Figma обязательно
              </SC.InfoTitle>
              <SC.InfoText>
                Покупая участие на Курсе, вы в том числе обязуетесь изучить
                Фигму по 
                <a
                  rel="noreferrer noopener"
                  href="https://vk.com/video-164662407_456239222"
                  target="_blank"
                >
                  бесплатному уроку Школы
                </a>
                . Домашки выдаём в ней. А ещё установить программы Adobe
                Illustrator и Photoshop (их изучать необязательно).
              </SC.InfoText>
            </SC.InfoItem>
          </SC.InfoBeforeBuyingColumn>
          <SC.InfoBeforeBuyingColumn>
            <SC.InfoItem>
              <SC.InfoTitle>
                <span>➃</span>
                Привязка к потоку
              </SC.InfoTitle>
              <SC.InfoText>
                В пакете «Группа» вы оплачиваете участие на конкретном потоке.
                Переносы между потоками невозможны.
              </SC.InfoText>
            </SC.InfoItem>
            <SC.InfoItem>
              <SC.InfoTitle>
                <span>➄</span>
                После оплаты
              </SC.InfoTitle>
              <SC.InfoText>
                Вам на почту придут данные для входа в учебный кабинет. Сами
                уроки появятся согласно учебному расписанию в пакете «Группа»,
                или сразу в пакете «Соло».
              </SC.InfoText>
            </SC.InfoItem>
            <SC.InfoItem>
              <SC.InfoTitle>
                <span>➅</span>
                По паспорту
              </SC.InfoTitle>
              <SC.InfoText>
                В форме оплаты указывайте свои настоящие имя и фамилию. В случае
                предоставления недостоверных данных мы оставляем за собой право
                расторгнуть договор обучения в одностороннем порядке.
              </SC.InfoText>
            </SC.InfoItem>
          </SC.InfoBeforeBuyingColumn>
        </SC.InfoBeforeBuying>
        <ContactsComponent />
      </Container>
    </SC.StyledSection>
  );
};

export default CoursePricePackages;
