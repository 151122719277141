import styled from "styled-components";
import "react-responsive-modal/styles.css";

import FoundationLogo from "assets/svgs/courses-order/foundation-of-graphic-design-v2-logo.svg";
import GranichCat from "assets/svgs/course-page/price-packages/granich-cat.svg";
import CrossIcon from "assets/svgs/course-page/price-packages/cross.svg";
import CheckIcon from "assets/svgs/course-page/price-packages/check.svg";

import {
  SectionTitleGaramond,
  WrapperWithBorder,
  Text19,
  Section,
} from "components/style";
import ButtonComponent from "components/layout/button";
import CourseTags from "components/pages/course-page/course-tags";

export const StyledSection = styled(Section)`
  background: white;
  margin-bottom: 0;
  margin-top: 2vw;
`;

export const StyledSectionTitleGaramond = styled(SectionTitleGaramond)`
  text-align: left;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 575px) {
    text-align: center;
    font-size: 17vw;
    margin-bottom: 12vw;

    span {
      font-size: 17vw;
    }
  }
`;

export const PackagesWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  position: relative;
  gap: 2vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const PackageBlock = styled(WrapperWithBorder)`
  background: white;
  padding: 1.5vw 2vw 2vw 2vw;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 575px) {
    padding: 5vw 9vw;
    margin-bottom: 6vw;
    max-width: 100%;
  }
`;

export const PackageCategoryHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3vw;
  justify-content: space-between;

  @media only screen and (max-width: 575px) {
    margin-bottom: 5vw;
    flex-direction: column;
    align-items: start;
  }
`;

export const PackageTitleWrapper = styled.div``;

export const PackageCategoryLabel = styled.div`
  font-weight: 600;
  font-size: 1.4vw;
  color: var(--granich-red);
  text-transform: uppercase;

  @media only screen and (max-width: 575px) {
    font-size: 5.4vw;
  }
`;

export const PackageCategoryTitle = styled.div`
  font-weight: 800;
  font-size: 4.4vw;
  color: var(--granich-black);
  line-height: 0.9;

  @media only screen and (max-width: 575px) {
    font-size: 17.4vw;
  }
`;

export const PackageBenefitsWrapper = styled.div`
  margin-bottom: 2.9vw;
`;

export const PackageBenefit = styled(Text19)`
  margin-bottom: 1.7vw;
  color: ${({ $isIncluded }) =>
    $isIncluded ? "var(--granich-black)" : "var(--granich-grey)"};

  span {
    color: ${({ $isIncluded }) =>
      $isIncluded ? "var(--granich-red)" : "var(--granich-grey)"};
    margin-right: 0.5vw;
    display: inline-block;
  }

  @media only screen and (max-width: 575px) {
    span {
      margin-right: 1.5vw;
    }

    margin-bottom: 3.7vw;
  }
`;

export const PackageFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const PackagePrice = styled.div`
  font-weight: 800;
  font-size: 4.9vw;
  margin-right: 1vw;
  color: var(--granich-black);
  white-space: nowrap;
  line-height: 0;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    font-size: 15.9vw;
    line-height: 1;
    margin-bottom: 4vw;
  }
`;

const getGridColumnsValue = (pricesCount, priceColumnWidth) => {
  let value = "";
  for (let i = 0; i < pricesCount; i++) {
    value += `${priceColumnWidth} `;
  }
  return value;
};

export const PriceRangeWrapper = styled.div`
  display: grid;
  ${({ pricesNumber = 4 }) =>
    `grid-template-columns: ${getGridColumnsValue(pricesNumber, "17.75vw")};`}
  grid-column-gap: 1.5vw;
  grid-row-gap: 1.5vw;
  @media only screen and (max-width: 575px) {
    grid-template-columns: ${getGridColumnsValue(2, "43.5vw")};
    grid-column-gap: 3vw;
    grid-row-gap: 3vw;
  }
`;

export const PriceCategory = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 0.6vw;
  transition: transform 0.2s ease;
  box-shadow: 0.1vw 0 0.5vw rgba(0, 0, 0, 0.15);

  :nth-child(2) {
    footer {
      background: #bfbfbf;
    }
  }
  :nth-child(3) {
    footer {
      background: var(--granich-light-grey);
    }
  }
  :last-child {
    footer {
      background: var(--granich-red);
    }
    div[id="price-category-package"] {
      color: var(--granich-red);
    }
    div[id="price-category-subtext"] {
      color: white;
    }
  }
  @media only screen and (max-width: 575px) {
    border-radius: 1.5vw;
    box-shadow: 0.5vw 0.5vw 1.5vw rgba(0, 0, 0, 0.15);
  }
`;

export const PriceCategoryButton = styled.a`
  display: block;
  background: var(--granich-black-gradient);
  border-radius: 0.5vw;
  color: white;
  font-size: 3vw;
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 400;
  padding: 0.3vw 0 0.5vw;
  text-align: center;
  transition: transform 0.2s ease;
  margin-bottom: 0.5vw;
  box-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.4);
  letter-spacing: -0.02vw;
  :hover {
    cursor: pointer;
    font-size: 3vw;
    color: white;
    font-weight: 400;
    transform: scale(1.05);
    letter-spacing: -0.02vw;
  }
  @media only screen and (max-width: 575px) {
    border-radius: 1vw;
    font-size: 7vw;
    padding: 1vw 0 1.3vw;
    box-shadow: 0.7vw 0.7vw 1.5vw rgba(0, 0, 0, 0.3);
    margin-bottom: 1.5vw;
    :hover {
      font-size: 7vw;
      transform: none;
    }
  }

  ${({ closed }) =>
    closed &&
    `
    background: rgba(0,0,0,0.2);
    :hover {
      transform: none;
      cursor: default;
    }
  `}
`;

export const FoundationLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  border: 0.15vw solid var(--granich-black);
  padding: 0.2vw;
  width: 3.4vw;
  min-width: 3.4vw;
  height: 3.4vw;

  @media only screen and (max-width: 575px) {
    width: 13vw;
    min-width: 13vw;
    height: 13vw;
    border-width: 0.8vw;
    padding: 1vw;
    margin-right: 2vw;

    svg {
      width: 4.5vw;
      height: 4.5vw;
      position: relative;
      right: -1vw;
    }
  }
`;

export const StyledFoundationLogo = styled(FoundationLogo)``;

export const StyledButtonComponent = styled(ButtonComponent)`
  padding: 1vw 1.2vw;
  span {
    font-size: 1.1vw;
    font-weight: 600;
  }

  div {
    width: 2.2vw;
    min-width: 2.2vw;
    height: 2.2vw;
  }

  svg {
    width: 100%;
    transform: scale(2) translateX(1%);
    width: 0.8vw;
    min-width: 0.8vw;
    height: 0.8vw;
  }

  ${({ $isCourseClosed }) =>
    $isCourseClosed &&
    `
    background: rgba(0,0,0,0.2);
    white-space: normal;
    text-align: left;
    :hover {
      transform: none;
      cursor: default;
      background: rgba(0,0,0,0.2);
    }
  `};

  @media only screen and (max-width: 575px) {
    padding: 3.5vw 4vw;
    border-radius: 15vw;
    position: relative;
    span {
      font-size: 4.5vw;
    }

    div {
      width: 9vw;
      min-width: 9vw;
      height: 9vw;
    }

    svg {
      width: 100%;
      transform: scale(2) translateX(1%);
      width: 3vw;
      min-width: 3vw;
      height: 3vw;
    }
  }
`;

export const StyledGranichCat = styled(GranichCat)`
  position: absolute;
  top: -10vw;
  right: 2.5vw;
  width: 13vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledCourseTags = styled(CourseTags)`
  min-width: 17vw;
  position: relative;
  top: 0.5vw;
  right: -1vw;

  @media only screen and (max-width: 575px) {
    min-width: 65vw;
    margin-bottom: 3vw;
    top: 6vw;
    right: 0;

    div {
      font-size: 5.2vw;
      border-width: 1vw;
      border-radius: 10vw;
      padding: 0.7vw 2vw;
      margin-bottom: 2.3vw;
      font-weight: 600;
      letter-spacing: -0.1vw;
    }
  }
`;

export const StyledCrossIcon = styled(CrossIcon)`
  width: 0.7vw;
  min-width: 0.7vw;
  fill: var(--granich-grey);
  position: relative;
  top: -0.1vw;

  @media only screen and (max-width: 575px) {
    width: 2.7vw;
    min-width: 2.7vw;
    top: -0.2vw;
  }
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 1vw;
  min-width: 1vw;
  fill: var(--granich-red);
  position: relative;
  top: -0.1vw;

  @media only screen and (max-width: 575px) {
    width: 3.5vw;
    min-width: 3.5vw;
  }
`;

// info before buying

export const InfoBeforeBuying = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2vw;
  margin-top: 3vw;
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin-top: 8vw;
    margin-bottom: 1vw;
  }
`;

export const InfoBeforeBuyingColumn = styled.div`
  width: 50%;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const InfoItem = styled.div`
  margin-bottom: 1vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 7vw;
  }
`;

export const InfoTitle = styled(Text19)`
  font-weight: 600;
  color: var(--granich-black);

  span {
    color: var(--granich-red);
    font-weight: 600;
    font-size: 2.4vw;
    line-height: 0;
    display: inline-block;
    margin-right: 0.35vw;
    top: 0.25vw;
    position: relative;
  }

  @media only screen and (max-width: 575px) {
    span {
      font-size: 9vw;
      top: 1vw;
      margin-right: 1vw;
    }
  }
`;

export const InfoText = styled(Text19)`
  color: var(--granich-black);
  position: relative;

  a {
    border-bottom: 0.1vw solid var(--granich-black);
  }
`;
