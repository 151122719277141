import React from "react";
import { useInView } from "react-intersection-observer";
import { Container } from "components/style";
import ImageZoomComponent from "components/common/image-zoom";
import { Section } from "components/pages/course-page/offer/style";
import CourseTags from "components/pages/course-page/offer/course-tags";
import StudyButtonComponent from "./StudyButtonComponent/StudyButtonComponent";
import * as SC from "./style";

const FoundationOffer = ({ data, courseDescr }) => {
  const { ref, inView } = useInView({ threshold: 0 });
  return (
    <Section>
      <Container>
        <div ref={ref}>
          <ImageZoomComponent customClass="foundation-illustration">
            <SC.MainIllustrationWrapper>
              <SC.StyledMainIllustration />
            </SC.MainIllustrationWrapper>
          </ImageZoomComponent>
          <SC.StyledWrapper ref={ref} type={data.coursetype}>
            <SC.Info>
              <SC.Tags className="noselect">
                <CourseTags course={data} />
              </SC.Tags>
              <SC.StyledFoundationLogo />
              <SC.MobileCompositionWrapper>
                <SC.StyledFormCompositionIcon />
                <SC.StyledBaseCompositionIcon />
                <SC.StyledPolaroidCompositionIcon />
                <SC.StyledFrameCompositionIcon />
              </SC.MobileCompositionWrapper>
              <SC.StyledDescr
                className="noselect"
                courseTitle={data.coursetitle?.text}
                type={data.coursetype}
              >
                {courseDescr ?? data.coursedescription?.text}
              </SC.StyledDescr>
              <StudyButtonComponent />
            </SC.Info>
          </SC.StyledWrapper>
        </div>
      </Container>
      <SC.StyledCSSTransition
        in={!inView}
        timeout={300}
        classNames="study-button-transition"
        unmountOnExit
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <StudyButtonComponent />
        </div>
      </SC.StyledCSSTransition>
    </Section>
  );
};

export default FoundationOffer;
