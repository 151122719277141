import * as Yup from "yup";

const getValidationSchema = () => {
  const myNameNewRegExp = /([^\d€\/\\.?":@#$])/;

  return Yup.object().shape({
    formParams: Yup.object().shape({
      first_name: Yup.string()
        .trim("Уберите пробелы в начале и в конце строки")
        .strict()
        .matches(myNameNewRegExp, "Неверные символы в Имени")
        .required("Сперва впишите Имя"),
      last_name: Yup.string()
        .trim("Уберите пробелы в начале и в конце строки")
        .strict()
        .matches(myNameNewRegExp, "Неверные символы в Фамилии")
        .required("Сперва впишите Фамилию"),
      email: Yup.string()
        .email("Это не электропочта >-<")
        .required("Сперва впишите Электропочту"),
    }),
  });
};

export default getValidationSchema;
