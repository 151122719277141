import React from "react";
import { Container } from "components/style";

import StarIcon from "assets/svgs/course-page/q-and-a/star.svg";
import FoundationLogo from "assets/svgs/courses-order/foundation-of-graphic-design-v2-logo.svg";

import data from "./data";
import * as SC from "./style";

const CourseQandNew = () => (
  <SC.StyledSection>
    <Container>
      <SC.StyledSectionTitle>
        <SC.StyledFoundationLogoWrapper>
          <FoundationLogo />
        </SC.StyledFoundationLogoWrapper>
        <span>Ещё</span> ответы-приветы
      </SC.StyledSectionTitle>
      <SC.MainWrapper>
        <SC.Column>
          {data.slice(0, 7).map(({ title, text }, idx) => (
            <SC.Item key={idx}>
              <SC.Title>
                <StarIcon />
                {title}
              </SC.Title>
              <SC.Text>{text}</SC.Text>
            </SC.Item>
          ))}
        </SC.Column>
        <SC.Column>
          {data.slice(7).map(({ title, text }, idx) => (
            <SC.Item key={idx}>
              <SC.Title>
                <StarIcon />
                {title}
              </SC.Title>
              <SC.Text>{text}</SC.Text>
            </SC.Item>
          ))}
        </SC.Column>
      </SC.MainWrapper>
    </Container>
  </SC.StyledSection>
);

export default CourseQandNew;
