import styled, { css } from "styled-components";
import Img from "gatsby-image";
import GlobeIcon from "assets/svgs/graph-design/graph-design-globe.svg";
import TonIcon from "assets/svgs/granich-figma/payment-choices/ton_symbol.svg";
import EthIcon from "assets/svgs/course-page/payment-choices/ethereum.svg";
import DolyamiIcon from "assets/svgs/granich-figma/payment-choices/dolyami.svg";
import MastercardIcon from "assets/svgs/granich-figma/payment-choices/mastercard.svg";
import MirIcon from "assets/svgs/granich-figma/payment-choices/mir.svg";
import SpbIcon from "assets/svgs/granich-figma/payment-choices/spb.svg";
import VisaIcon from "assets/svgs/granich-figma/payment-choices/visa.svg";
import CorporateIcon from "assets/svgs/granich-figma/payment-choices/corporate.svg";
import DiadockIcon from "assets/svgs/diadock-icon.svg";
import PdfIcon from "assets/svgs/pdf-diadock-icon.svg";
import {
  Section,
  WrapperWithBorder,
  SectionTitleGaramond,
  Text16,
} from "components/style";

export const StyledSection = styled(Section)`
  background: white;
  margin-top: 0;
  padding-top: 5vw;
  margin-bottom: 0;
  padding-bottom: 5vw;
  @media only screen and (max-width: 575px) {
    padding-bottom: 10vw;
  }
`;

export const StyledSectionTitleGaramond = styled(SectionTitleGaramond)`
  text-align: left;
  @media only screen and (max-width: 575px) {
    text-align: center;
    font-size: 14vw;
    letter-spacing: -0.01vw;
    line-height: 0.8;
    margin-top: 10vw;
    margin-bottom: 7vw;

    span {
      font-size: 14vw;
    }
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5vw;

  @media only screen and (max-width: 575px) {
    display: block;
  }
`;

export const PaymentChoice = styled(WrapperWithBorder)`
  background: white;
  border-radius: 1.5vw;
  padding: 2vw;
  position: relative;
  height: 17.5vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 5vw;
    border-radius: 4.5vw;
    padding: 4vw 8vw;
    height: auto;
  }
`;

export const PaymentChoiceInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media only screen and (max-width: 575px) {
    justify-content: start;
  }
`;

export const FirstPaymentChoice = styled(PaymentChoice)`
  height: 17vw;
  @media only screen and (max-width: 575px) {
    height: 103vw;
  }
`;

export const SecondPaymentChoice = styled(PaymentChoice)`
  height: 17vw;
  @media only screen and (max-width: 575px) {
    height: 87vw;
  }
`;

export const GiftPaymentChoice = styled(PaymentChoice)`
  margin-top: 1.5vw;
  height: 16vw;

  @media only screen and (max-width: 575px) {
    margin-top: 0;
    height: 133vw;
  }
`;

export const PaymentChoiceTitle = styled.div`
  font-size: 2.5vw;
  font-weight: 700;
  margin-bottom: 0.5vw;
  letter-spacing: -0.04vw;
  line-height: 1.1;

  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    margin-bottom: 3vw;
  }
`;

export const PaymentChoiceText = styled(Text16)``;

export const FirstBlockPaymentChoiceText = styled(PaymentChoiceText)`
  max-width: 61%;

  @media only screen and (max-width: 575px) {
    max-width: 100%;
  }
`;

export const SecondBlockPaymentChoiceText = styled(PaymentChoiceText)`
  margin-top: -0.5vw;
  max-width: 21vw;

  @media only screen and (max-width: 575px) {
    max-width: 100%;
    span {
      margin-left: 0;
    }
  }
`;

export const ThirdBlockPaymentChoiceText = styled(PaymentChoiceText)`
  max-width: 18vw;

  @media only screen and (max-width: 575px) {
    max-width: 100%;
  }
`;

export const FourthBlockPaymentChoiceText = styled(PaymentChoiceText)`
  max-width: 58%;

  @media only screen and (max-width: 575px) {
    max-width: 100%;
  }
`;

export const FifthBlockPaymentChoiceText = styled(PaymentChoiceText)`
  max-width: 46vw;

  @media only screen and (max-width: 575px) {
    max-width: 100%;
  }
`;

export const PaymentChoiceIllustrationsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledGlobeIcon = styled(GlobeIcon)`
  position: absolute;
  width: 12.5vw;
  min-width: 12.5vw;
  top: 2.5vw;
  right: 2vw;

  @media only screen and (max-width: 575px) {
    position: static;
    width: 50vw;
    min-width: 50vw;
    margin-top: 3vw;
    margin-bottom: 2vw;
  }
`;

const CoinIconStyle = css`
  z-index: 2;
  width: 8vw;
  min-width: 8vw;
  position: absolute;

  @media only screen and (max-width: 575px) {
    position: static;
    top: auto;
    left: auto;
    width: 31vw;
    min-width: 31vw;
    margin-top: 2.5vw;
  }
`;

export const StyledTonIcon = styled(TonIcon)`
  ${CoinIconStyle};
  right: 8.7vw;
  top: 2vw;

  @media only screen and (max-width: 575px) {
    margin-right: 7vw;
  }
`;

export const StyledEthIcon = styled(EthIcon)`
  ${CoinIconStyle};
  right: 2vw;
  top: 7.1vw;
`;

export const StyledDolyamiIcon = styled(DolyamiIcon)`
  position: absolute;
  top: 9.1vw;
  right: 3.3vw;
  width: 8.8vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 7vw;
    right: 40vw;
    width: 33vw;
  }
`;
export const StyledMastercardIcon = styled(MastercardIcon)`
  position: absolute;
  top: 11.6vw;
  right: 9.6vw;
  width: 3.5vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 16vw;
    right: 14.3vw;
    width: 14vw;
  }
`;
export const StyledMirIcon = styled(MirIcon)`
  position: absolute;
  top: 6vw;
  right: 2.2vw;
  width: 4.5vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 19vw;
    right: 32.3vw;
    width: 18vw;
  }
`;
export const StyledSpbIcon = styled(SpbIcon)`
  position: absolute;
  top: 5.2vw;
  right: 7.8vw;
  width: 5.5vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 15vw;
    right: auto;
    left: 8.5vw;
    width: 22vw;
  }
`;
export const StyledVisaIcon = styled(VisaIcon)`
  position: absolute;
  top: 12vw;
  right: 2.9vw;
  width: 5.2vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 6vw;
    right: 14.3vw;
    width: 21vw;
  }
`;

export const StyledCorporateIcon = styled(CorporateIcon)`
  position: absolute;
  bottom: 1.7vw;
  right: 2.1vw;
  width: 9.8vw;
  min-width: 9.8vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const CorporateDocsWrapper = styled.div`
  margin-top: 0.3vw;
  display: flex;
  align-items: center;
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    position: absolute;
    align-items: start;
    bottom: 5.5vw;
    flex-direction: column;
    margin: 0;
  }
`;

export const Mail = styled.span`
  position: relative;
  font-weight: 400;
  font-size: 1.23vw;
  color: #969696;
  display: block;
  border-bottom: solid 1px var(--granich-light-grey);
  background: none;
  user-select: none;
  margin-top: -0.15vw;
  margin-right: 0.3vw;
  margin-left: 0.4vw;
  line-height: 1;

  :hover {
    font-weight: 400;
    font-size: 1.23vw;
    cursor: pointer;
    border-bottom: solid 1px #969696;
  }

  @media only screen and (max-width: 575px) {
    font-size: 5vw;
    padding-bottom: 0;
    margin-left: 1.6vw;
    :hover {
      font-size: 5vw;
    }
  }
`;

export const StyledDiadockIcon = styled(DiadockIcon)`
  background: white;
  border-radius: 0.6vw;
  width: 11vw;
  min-width: 11vw;
  margin-right: 0.8vw;
  border: 0.1vw solid var(--granich-grey);

  @media only screen and (max-width: 575px) {
    width: 45vw;
    min-width: 45vw;
    border-radius: 1.5vw;
    margin-bottom: 3.8vw;
    border: 0.5vw solid rgba(209, 209, 209, 1);
  }
`;

export const StyledPdfIcon = styled(PdfIcon)`
  width: 1.5vw;
  min-width: 1.5vw;
  position: relative;
  left: 0.2vw;
  top: 0.1vw;
  margin-right: 1vw;

  @media only screen and (max-width: 575px) {
    width: 6.5vw;
    min-width: 6.5vw;
    margin-right: 2vw;
  }
`;

export const PdfLink = styled.a`
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  span {
    font-size: 1.21vw;
    border-bottom: solid 1px var(--granich-black);
    white-space: nowrap;
  }

  @media only screen and (max-width: 575px) {
    span {
      font-size: 3.5vw;
    }
  }
`;

export const GiftExampleImage = styled(Img)`
  position: absolute !important;
  top: 2.5vw;
  right: 16.5vw;
  width: 7.7vw;
  border: 0.15vw solid #969696;
  border-radius: 0.6vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 5vw;
    right: 25vw;
    width: 20vw;
    border-width: 0.5vw;
    border-radius: 1.5vw;
    z-index: 2;
  }
`;

export const PresentImage = styled(Img)`
  position: absolute !important;
  top: 1.1vw;
  right: 3.5vw;
  width: 12vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 2vw;
    width: 29vw;
    right: 49vw;
    z-index: 1;
  }
`;
