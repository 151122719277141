import React from "react";

import * as SC from "./style";

const data = [
  {
    title: "У вас есть 2 часа ежедневно",
    text: "Удостоверьтесь, что у вас есть хотя бы пара часов на обучение ежедневно. Мы сторонники интенсивного подхода. При этом, на каждое домашнее задание даётся строгий дедлайн.",
  },
  {
    title: "Общаемся на ты",
    text: "В рамках Курса мы все общаемся на ты. Это упрощает коммуникацию.",
  },
  {
    title: "NO OZON/WB BEHAVIOUR",
    text: "Что бы это ни значило",
  },
  {
    title: "Можно забронировать себе место?",
    text: "Нет. Но есть утешительный приз. Можно создать заказ на участие на Курсе и оплатить его позднее, по цене на момент создания заказа ;)",
  },
  {
    title: "Расчёт — сразу или по частям?",
    text: "Расчет всей суммой перед началом Курса. Есть возможность оформить рассрочку или долями.",
  },
  {
    title:
      "Почему нужно устанавливать программы (Photoshop, Illustrator) на английском языке?",
    text: "Потому что дизайн и вёрстка — явление, в первую очередь, зарубежное. Русский перевод этих программ хромает. А знание всех терминов программы на родном английском ещё множество раз вас спасёт. Все лучшие статьи и туториалы этих программ идут на английском.",
  },
  {
    title: "Какой компьютер нужен?",
    text: "Компьютеру желательно: Сделать 16 гб оперативки (чтобы компьютер не забывал большие макеты). Поставить SSD 120-240 гб, на котором будет установлена ОС и программы от Adobe (всё в разы быстрее станет грузиться). Отдельная видеокарта необязательна (хоть и желательна для более быстрой графики). Из ноутбуков на Windows уважаем Xiaomi Notebook Pro. А на Эппл разумеется MacBook Pro на чипе M1 (любого года выпуска).",
  },
  {
    title: "Вы умеете пользоваться ПК",
    text: (
      <div>
        На Курсе мы обучаем графическому дизайну. Но не обучаем пользоваться
        компьютером. Курс подходит всем, кто умеет обращаться с компьютером на
        уровне обычного пользователя. Это значит, что вы:
        <ul>
          <li>1. Пользуетесь облачным диском (или знаете принцип работы).</li>
          <li>2. Можете сами с нуля установить программы от Adobe и Figma.</li>
          <li>
            3. Способны сами разобраться как установить новый шрифт (например, с
            Google Fonts).
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Как ученики получают материалы уроков?",
    text: "Материалы открываются в вашем учебном кабинете. Строго по графику, в 00:00 по московскому времени по средам. Смотреть их можно в любое время.",
  },
  {
    title: "Будет ли какой-то выпускной проект?",
    text: "Да, на Курсе есть Экзамен. Нужно сдавать творческие макеты и докручивать их до зачёта с кураторами. Нужно получить минимум 3 зачёта для успешного завершения Курса.",
  },
  {
    title: "Где будет основная площадка для общения?",
    text: "Будут Учебный и Инфо- чаты в Телеграме. Вдобавок, в Телеграме вы будете присылать докрутки своего Экзамена кураторам в личку.",
  },
  {
    title: "По поводу площадки для проведения Курса",
    text: (
      <div>
        Уроки Курса выдаём онлайн, через учебную платформу. Вход на неё есть в
        меню сайта{" "}
        <a
          rel="noreferrer noopener"
          href="https://granich.design"
          target="_blank"
        >
          www.granich.design
        </a>
        , кнопка «Войти». Там будут приходить все материалы (видео, презентации,
        ДЗ). После оплаты в разделе «Обучение»→«Тренинги» появится тренинг Курса
        вашего потока. Если этого не случилось — напишите нам на почту:{" "}
        <SC.StyledCopyMailToClipboardComponent>
          hello@granich.design
        </SC.StyledCopyMailToClipboardComponent>
      </div>
    ),
  },
];

export default data;
