import styled from "styled-components";

import FoundationLogo from "assets/svgs/courses-order/foundation-of-graphic-design-v2-logo.svg";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";

import {
  StyledButtonComponent,
  FoundationLogoWrapper,
} from "components/pages/index/main-education/style";

import { WrapperWithBorder, Text16 } from "components/style";

export const FormWrapper = styled.div``;

export const StyledWrapperWithBorder = styled(WrapperWithBorder)`
  background: white;
  width: 41vw;
  padding: 2.2vw 2.7vw 2.2vw 2.7vw;
  @media only screen and (max-width: 575px) {
    padding: 5vw 8vw;
    width: 100%;
  }
`;

export const FormContainer = styled.div``;

export const FormTitle = styled.h2`
  font-size: 3.45vw;
  font-weight: 800;
  letter-spacing: -0.05vw;
  line-height: 0.85;
  margin-bottom: 0.5vw;
  @media only screen and (max-width: 575px) {
    font-size: 8vw;
    width: 100%;
    margin-bottom: 5.5vw;
  }
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media only screen and (max-width: 575px) {
    br {
      display: none;
    }
  }
`;

export const PackagesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 2vw;
    flex-direction: column;
    align-items: start;
  }
`;

export const Package = styled.div`
  padding: 0.45vw 0.55vw;
  margin-right: 1vw;
  font-size: 1.6vw;
  border-radius: 10vw;
  border: 0.3vw solid
    ${({ $isChosen }) =>
      $isChosen ? "var(--granich-black)" : "var(--granich-grey)"};
  color: ${({ $isChosen }) =>
    $isChosen ? "var(--granich-black)" : "var(--granich-grey)"};
  display: flex;
  align-items: center;
  white-space: nowrap;
  letter-spacing: 0.01vw;
  font-weight: 600;

  ${({ $isDisabled }) => $isDisabled && "text-decoration: line-through;"}

  :last-child {
    margin-right: 0;
  }

  ${({ $isDisabled }) =>
    !$isDisabled &&
    `
      :hover {
        cursor: pointer;
      }
    `}

  span {
    display: inline-block;
    border: 0.3vw solid
      ${({ $isChosen }) =>
        $isChosen ? "var(--granich-black)" : "var(--granich-grey)"};
    background: ${({ $isChosen }) =>
      $isChosen ? "var(--granich-red)" : "white"};
    width: 2vw;
    min-width: 2vw;
    height: 2vw;
    margin-right: 0.5vw;
    border-radius: 50%;
  }

  @media only screen and (max-width: 575px) {
    font-size: 6vw;
    padding: 1.8vw 2.3vw;
    line-height: 0;
    margin-right: 0;
    margin-bottom: 3vw;
    border-width: 1vw;

    span {
      width: 7vw;
      min-width: 7vw;
      height: 7vw;
      margin-right: 2vw;
      border-width: 1vw;
    }
  }
`;

export const PackagesDescr = styled(Text16)`
  margin-bottom: 1vw;
  position: relative;

  @media only screen and (max-width: 575px) {
    font-size: 4.4vw;
    line-height: 1.4;
    margin-bottom: 6vw;
  }
`;

export const Input = styled.input`
  display: block;
  height: 2.7vw;
  border: none;
  border-bottom: 0.3vw solid var(--granich-black);
  padding: 0.5vw 0;
  width: 100%;
  font-size: 1.5vw;
  font-weight: 600;
  position: relative;
  margin-bottom: 1.5vw;
  ${props =>
    props.errorStyle &&
    `
        border-color: var(--granich-purple-red);
        ::placeholder {
            color: var(--granich-purple-red);
        }
    `}

  @media only screen and (max-width: 575px) {
    height: 10.5vw;
    font-size: 5.1vw;
    border-width: 1vw;
    margin-bottom: 3vw;
  }
`;

export const FormPolitikaLabel = styled.div`
  font-size: 0.9vw;
  letter-spacing: -0.02vw;
  line-height: 1.4;
  color: var(--granich-grey);
  max-width: 13.5vw;

  a {
    color: var(--granich-grey);
    font-weight: 400;
    display: inline-block;
    position: relative;

    :after {
      content: "";
      height: 0.05vw;
      width: 100%;
      background: var(--granich-grey);
      position: absolute;
      bottom: -0.05vw;
      left: 0;
    }
  }
  @media only screen and (max-width: 575px) {
    font-size: 3vw;
    margin-top: 3vw;
    width: 100%;
    max-width: 100%;
  }
  ${props =>
    props.line &&
    `
        border-bottom: 1px solid #dedede;
        :last-child {
            border-bottom: none;
        }
        @media only screen and (max-width: 575px) {
            padding-bottom: 3vw;
        }
      `}
`;

export const FormButton = styled.button`
  background: var(--granich-black-gradient);
  color: white;
  padding: 1vw 3vw;
  border-radius: 0.5vw;
  font-size: 1.65vw;
  transition: transform 0.3s ease;
  box-shadow: 0.2vw 0.2vw 0.4vw rgba(0, 0, 0, 0.4);
  user-select: none;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }
  @media only screen and (max-width: 575px) {
    font-size: 4.4vw;
    padding: 3vw 8vw;
    border-radius: 1.5vw;
    box-shadow: 0.5vw 0.5vw 1vw rgba(0, 0, 0, 0.25);
    :hover {
      transform: none;
    }
  }
`;

export const FormFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: start;
    margin-top: 7vw;
  }
`;

export const FormCross = styled.div`
  position: absolute;
  top: -1.2vw;
  right: -1.5vw;
  svg {
    fill: rgba(150, 150, 150, 1);
    width: 6vw;
    height: 6vw;
  }
  :hover {
    cursor: pointer;
    svg {
      fill: var(--granich-red);
    }
  }
  @media only screen and (max-width: 575px) {
    svg {
      width: 14vw;
      height: 14vw;
    }

    right: -5vw;
    top: -2.5vw;
  }
`;

export const CustomBr = styled.br`
  display: none !important;
  @media only screen and (max-width: 575px) {
    display: block !important;
  }
`;

export const StyledFoundationLogo = styled(FoundationLogo)``;

export const StyledPayButtonComponent = styled(StyledButtonComponent)`
  margin-right: 1.9vw;
`;

export const StyledFoundationLogoWrapper = styled(FoundationLogoWrapper)``;

export const StyledCopyMailToClipboardComponent = styled(
  CopyMailToClipboardComponent
)``;

export const Email = styled.span`
  color: var(--granich-grey);
  display: inline-block;
  border-bottom: 0.1vw solid var(--granich-grey);
  background: none;
  position: relative;
  :hover {
    cursor: pointer;
    border-bottom: 0.1vw solid var(--granich-grey);
  }

  @media only screen and (max-width: 575px) {
    padding-bottom: 0;
  }
`;
