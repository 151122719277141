import styled from "styled-components";

import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";

import { Section, Text19, SectionTitleGaramond } from "components/style";
import { FoundationLogoWrapper } from "components/pages/index/main-education/style";

export const StyledSection = styled(Section)`
  margin-top: 0;
  background: white;
  margin-bottom: 0;
  padding-bottom: 3vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const StyledSectionTitle = styled(SectionTitleGaramond)`
  text-align: left;
  margin-bottom: 3.2vw;

  @media only screen and (max-width: 575px) {
    font-size: 16vw;
    line-height: 0.8;
    margin-bottom: 9.2vw;
    span {
      font-size: 16vw;
    }
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 2vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  width: 50%;

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const Item = styled.div`
  margin-bottom: 2vw;

  @media only screen and (max-width: 575px) {
    margin-bottom: 6vw;
  }
`;

export const Title = styled(Text19)`
  font-weight: bold;
  color: var(--granich-black);

  svg {
    width: 1.3vw;
    height: 1.3vw;
    position: relative;
    min-width: 1.3vw;
    fill: var(--granich-red);
    top: 0.01vw;
    margin-right: 0.5vw;
  }

  @media only screen and (max-width: 575px) {
    svg {
      width: 4vw;
      height: 4vw;
      min-width: 4vw;
      top: 0.1vw;
      margin-right: 1vw;
    }
  }
`;

export const Text = styled(Text19)`
  margin-bottom: 2vw;
  color: var(--granich-black);

  a {
    border-bottom: 0.1vw solid var(--granich-black);
  }
`;

export const StyledFoundationLogoWrapper = styled(FoundationLogoWrapper)`
  display: inline-block;
  width: 9.7vw;
  min-width: 9.7vw;
  height: 9.7vw;
  border-width: 0.3vw;
  position: relative;
  top: 1.7vw;
  margin-right: 1.5vw;

  svg {
    width: 8vw;
    height: 8vw;
    position: relative;
    right: -1vw;
    top: 0.3vw;
  }

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledCopyMailToClipboardComponent = styled(
  CopyMailToClipboardComponent
)`
  border-bottom: 0.1vw solid var(--granich-black);
`;
